// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-dsp-all-images-in-dir-js": () => import("../src/templates/dspAllImagesInDir.js" /* webpackChunkName: "component---src-templates-dsp-all-images-in-dir-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

